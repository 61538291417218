import { useEffect, useState } from 'react';
import yaml from 'js-yaml';
import { Node, XYPosition } from 'react-flow-renderer';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router';
import {
  injectVisualEntry,
  useCreateVisualMutation,
  useGetVisualAssetQuery,
  useGetVisualDocumentQuery,
  useGetVisualQuery,
  useUpdateVisualMutation,
} from '../redux/services/visuals/api';
import { useAppDispatch, useAppSelector } from '../redux/store/index';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import {
  setVisualJson,
  setVisualAction,
  setVisualOldText,
  setVisualAsset,
  setVisualFileName,
  setIsVisualLoading,
} from '../redux/modules/editor/slice';
import { ActionsType } from '../typescript/interfaces/editor.interface';
import { ROUTES_PATH, YAML_LANGUAGE } from '../constants';
import { createBlob, createFile } from '../utils/editorPureFn';
import {
  FORMDATA_ASSET,
  FORMDATA_DEFINITION,
} from '../common/Diagram/library/Modal';
import useSnackbar from './useSnackbar';
import useYAMLEditor from './useYAMLEditor';
import { createVisualTemplate } from '../common/Diagram/CreateProjectDiagram/utils';

export const parseToJSON = (value: string) => {
  try {
    const parsedValue = yaml.load(value);
    if (!parsedValue) return {};
    return parsedValue;
  } catch (err) {
    console.warn({ err });
    return {};
  }
};
const parseToYAML = (value: { [key: string]: any }) =>
  yaml.dump(value, {
    noCompatMode: true,
  });

const useYAMLVisualEditorState = ({ id }: { id: string }) => {
  const location = useLocation();

  const yamlDocumentJSON = useAppSelector(
    (state: IAppState) => state.editor.visual.json,
  );
  const asset = useAppSelector((state: IAppState) => state.editor.visual.asset);
  const fileName = useAppSelector(
    (state: IAppState) => state.editor.visual.fileName,
  );
  const isLoading = useAppSelector(
    (state: IAppState) => state.editor.visual.isLoading,
  );
  const [textData, setTextData] = useState<string>();
  const [name, setName] = useState<string>();
  const { yamlInstance } = useYAMLEditor({ isSetMarkers: false });

  const { showSuccess, showForbiddenError } = useSnackbar();

  const { data: visualData, isLoading: isVisualDataLoading } =
    useGetVisualQuery(id, { skip: !id || id?.startsWith('PROJ') });
  const { data: visuals, isLoading: isVisualsLoading } =
    useGetVisualDocumentQuery(id, { skip: !id || id?.startsWith('PROJ') });
  const { data: visualAsset } = useGetVisualAssetQuery(
    { id, version: visualData?.version },
    { skip: (!id && !visualData) || id?.startsWith('PROJ') },
  );

  const [
    createVisual,
    {
      isSuccess: isCreateVisualSuccess,
      error: createVisualError,
      data: createVisualData,
    },
  ] = useCreateVisualMutation();
  const [
    updateVisual,
    { isSuccess: isUpdateVisualSuccess, error: updateError },
  ] = useUpdateVisualMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_CONFIG)) return;
    if (!id) return;
    if (!visuals) return;
    const jsonVisuals = parseToJSON(visuals);
    if (JSON.stringify(visuals) !== JSON.stringify(jsonVisuals)) {
      dispatch(setVisualJson(jsonVisuals));
      setTextData(visuals);
      setVisualOldText(visuals);
      dispatch(setVisualFileName(`${jsonVisuals?.name}.${YAML_LANGUAGE}`));
    }
  }, [visuals]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_CONFIG)) return;
    if (id) return;
    if (visuals) return;
    const jsonVisuals = parseToJSON(createVisualTemplate({}));
    dispatch(setVisualJson(jsonVisuals));
    setTextData(visuals);
    setVisualOldText(visuals);
    dispatch(setVisualFileName(`${jsonVisuals?.name}.${YAML_LANGUAGE}`));
  }, [id, visuals]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_CONFIG)) return;
    if (visuals && yamlInstance) yamlInstance?.setValue(visuals);
  }, [visuals, yamlInstance]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_CONFIG)) return;
    if (Object.keys(yamlDocumentJSON).length === 0) return;
    setTextData(parseToYAML(yamlDocumentJSON));
    setName(yamlDocumentJSON?.name || '');
    dispatch(setVisualFileName(`${yamlDocumentJSON?.name}.yaml`));
  }, [yamlDocumentJSON]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_CONFIG)) return;
    if (!visualAsset) return;
    handleAssets(visualAsset);
  }, [visualAsset]);

  useEffect(() => {
    if (isCreateVisualSuccess && createVisualData) {
      injectVisualEntry({ data: createVisualData });
      showSuccess(`Visual created: ${yamlDocumentJSON.name}`);
    }
    if (isUpdateVisualSuccess) {
      showSuccess(`Visual updated: ${yamlDocumentJSON.name}`);
    }
  }, [isCreateVisualSuccess, createVisualData, isUpdateVisualSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: createVisualError,
      customForbiddenMessage:
        "You don't have enough permissions to create a visual",
      customUniqueViolationMessage: 'Visual with this name already exists',
      customDefaultMessage: 'Failed to create visual',
    });
  }, [createVisualError]);

  useEffect(() => {
    showForbiddenError({
      error: updateError,
      customForbiddenMessage:
        "You don't have enough permissions to update a visual",
      customDefaultMessage: 'Failed to update visual',
    });
  }, [updateError]);

  useEffect(() => {
    if (isVisualDataLoading || isVisualsLoading) {
      dispatch(setIsVisualLoading(true));
      return;
    }
    dispatch(setIsVisualLoading(false));
  }, [isVisualDataLoading, isVisualsLoading]);

  const handleTextValue = (newText: string) => {
    const jsonVisuals = parseToJSON(newText);
    dispatch(setVisualJson(jsonVisuals));
  };

  const handleUploadValue = (newValue: string) => {
    dispatch(setVisualJson(parseToJSON(newValue)));
    yamlInstance.setValue(newValue);
  };

  const handleNameChange = (newValue: string) => {
    setName(newValue);
    dispatch(setVisualJson({ ...yamlDocumentJSON, name: newValue }));
    dispatch(setVisualFileName(`${newValue}.yaml`));
  };

  const handleNodePositionChange = ({
    nodeId,
    newPosition,
  }: {
    nodeId: string;
    newPosition: XYPosition;
  }) => {
    const newPosFixed = {
      x: +newPosition.x.toFixed(0),
      y: +newPosition.y.toFixed(0),
    };
    const newState = {
      ...yamlDocumentJSON,
      flairs: {
        ...yamlDocumentJSON.flairs,
        [nodeId]: {
          ...yamlDocumentJSON.flairs[nodeId],
          placement: newPosFixed,
        },
      },
    };
    if (JSON.stringify(newState) !== JSON.stringify(yamlDocumentJSON))
      dispatch(setVisualJson(newState));
  };

  const handleNodeSizeChange = (
    nodeId: string,
    newSize: { width: number; height: number },
  ) => {
    const newSizeFixed = {
      width: +newSize.width.toFixed(0),
      height: +newSize.height.toFixed(0),
    };

    const newState = {
      ...yamlDocumentJSON,
      flairs: {
        ...yamlDocumentJSON.flairs,
        [nodeId]: {
          ...yamlDocumentJSON.flairs[nodeId],
          size: newSizeFixed,
        },
      },
    };

    if (JSON.stringify(newState) !== JSON.stringify(yamlDocumentJSON)) {
      const action = { type: ActionsType.nodeResized, nodeId: { id: nodeId } };
      dispatch(setVisualAction(action));
      dispatch(setVisualJson(newState));
    }
  };

  const handleNewNode = (data) => {
    const [nodeId] = Object.keys(data);
    dispatch(
      setVisualJson({
        ...yamlDocumentJSON,
        flairs: { ...yamlDocumentJSON.flairs, ...data },
      }),
    );
    const action = { type: ActionsType.nodeSelected, nodeId };
    dispatch(setVisualAction(action));
  };

  const handleNodeClick = (_, node: Node) => {
    const action = { type: ActionsType.nodeSelected, nodeId: node };
    dispatch(setVisualAction(action));
  };

  const handleAssets = (newAsset) => {
    dispatch(setVisualAsset(newAsset));
  };

  const handleSaveAction = () => {
    const form = new FormData();

    form.append(
      FORMDATA_ASSET,
      createFile(
        createBlob(asset),
        `${yamlDocumentJSON.name}.svg`,
        'image/svg+xml',
      ),
    );
    form.append(
      FORMDATA_DEFINITION,
      createFile(
        createBlob(textData),
        `${yamlDocumentJSON.name}.yaml`,
        'text/yaml',
      ),
    );

    if (id) {
      updateVisual({ id, form });
      return;
    }
    createVisual(form);
  };

  const handleFileDownload = (type: 'svg' | typeof YAML_LANGUAGE) => {
    let file;
    if (fileName.endsWith('svg') || fileName.endsWith(YAML_LANGUAGE)) {
      file = fileName;
    } else {
      file = `${yamlDocumentJSON.name || 'visual'}.${type}`;
    }
    if (type === YAML_LANGUAGE) {
      saveAs(createBlob(textData), file);
    }
    if (type === 'svg') {
      saveAs(createBlob(asset), file);
    }
  };

  const handleFileName = (str: string) => {
    dispatch(setVisualFileName(str));
  };

  const handleFileUploading = async (
    event: React.ChangeEvent<HTMLInputElement>,
    ref: React.MutableRefObject<HTMLInputElement>,
    type: 'svg' | 'yaml',
  ) => {
    const [file] = event.currentTarget.files;
    const innerData = await file.text();

    if (type === YAML_LANGUAGE) {
      handleUploadValue(innerData);
    }
    if (type === 'svg') {
      handleAssets(innerData);
    }
    if (ref?.current?.value) ref.current.value = null;
    if (event?.currentTarget?.value) event.currentTarget.value = null;
  };

  return {
    json: yamlDocumentJSON,
    asset,
    text: textData,
    name,
    fileName,
    isLoading,
    handleTextValue,
    handleUploadValue,
    handleNameChange,
    handleNodePositionChange,
    handleNodeSizeChange,
    handleNewNode,
    handleNodeClick,
    handleAssets,
    handleSaveAction,
    handleFileDownload,
    handleFileName,
    handleFileUploading,
  };
};

export default useYAMLVisualEditorState;
