import {
  Avatar,
  Box,
  ListSubheader,
  Typography,
  alpha,
  styled,
} from '@mui/material';

export const UserAction = styled(Box)(({ theme }) => ({
  display: 'flex',
  lineHeight: 1,
}));

export const Head = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: alpha(theme.palette.text.secondary, 0.75),
  fontWeight: 500,
}));

export const UserName = styled('div')(({ theme }) => ({
  display: 'flex',
  lineHeight: 1,
  marginBottom: theme.spacing(1),
}));

export const UserRole = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'left',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
}));

export const UserAvatar = styled(Avatar)({
  marginRight: '0.75rem',
  width: '2.5rem',
  height: '2.5rem',
});

export const Logo = styled('img')({
  width: '100%',
  maxWidth: '170px',
  minWidth: '116px',
});
