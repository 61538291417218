import React, { Suspense, lazy, memo, useEffect, useState } from 'react';
import { FormControl, InputLabel, useTheme } from '@mui/material';
import L, { LatLng } from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Marker, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import { AddLocationRounded } from '@mui/icons-material';
import Loader from '../Loader';
import useSnackbar from '../../hooks/useSnackbar';
import {
  useCreateSiteMutation,
  useUpdateSiteMutation,
  useGetSitesQuery,
} from '../../redux/services/sites/api';
import DialogComponent from './DialogComponent';
import { ModalContent } from './styles';
import Input from '../Input';
import { retry } from '../../utils/retry';
import { isForbiddenError, isUniqueViolationError } from '../../redux/utils';
import { notifications } from '../../utils/stringUtils';
import { Site } from '../../redux/services/sites/types';

const PcMap = lazy(() =>
  retry(() => import('../../components/system/map/Map')),
);
interface Props {
  isOpened: boolean;
  onClose: () => void;
  onCreate?: (siteId: string) => void;
  actionTitle?: string;
  siteId?: string | null;
  setIsShouldValidateSites?: React.Dispatch<React.SetStateAction<boolean>>;
  updateSitesList?: (newSite: Site) => void;
}

const NAME_MAX_LENGTH = 40;
const DESCRIPTION_MAX_LENGTH = 1024;
const UNSPECIFIED_COUNTRY_CODE = '--';

const onlineIcon = L.icon({
  iconUrl: '/images/location-success.svg',
  iconRetinaUrl: '/images/location-success.svg',
  iconSize: [38, 95],
  iconAnchor: [20, 37],
  popupAnchor: [0, -16],
  shadowSize: [68, 95],
  shadowAnchor: [22, 94],
});

const onlineDarkIcon = L.icon({
  iconUrl: '/images/location-success-dark.svg',
  iconRetinaUrl: '/images/location-success-dark.svg',
  iconSize: [38, 95],
  iconAnchor: [20, 37],
  popupAnchor: [0, -16],
  shadowSize: [68, 95],
  shadowAnchor: [22, 94],
});

const CurrentLocationHandler = memo(() => {
  const map = useMapEvents({
    locationfound(e) {
      map.flyTo(e.latlng, 6);
    },
  });
  map.locate();
  return null;
});
CurrentLocationHandler.displayName = 'CurrentLocationHandler';

type MapCenterTrackerProps = {
  onChange: (location: LatLng) => void;
};
function MapCenterTracker({ onChange }: MapCenterTrackerProps) {
  const map = useMap();

  function onMove() {
    onChange(map.getCenter());
  }

  useEffect(() => {
    map.on('move', onMove);
    return () => {
      map.off('move', onMove);
    };
  }, [map, onMove]);

  return null;
}

class ReverseOpenStreetMapProvider extends OpenStreetMapProvider {
  async reverseSearchCountryCode(latLng: LatLng): Promise<any> {
    const url = this.endpoint({
      query: { lat: latLng.lat, lon: latLng.lng },
      type: 1, // RequestType.REVERSE
    });

    const request = await fetch(url);
    const json = await request.json();
    return (
      json?.address?.country_code?.toUpperCase() || UNSPECIFIED_COUNTRY_CODE
    );
  }
}

function CreateSite(props: Props) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const {
    isOpened,
    onClose,
    onCreate,
    actionTitle,
    siteId,
    setIsShouldValidateSites,
    updateSitesList,
  } = props;
  const action = actionTitle === 'Add' ? 'create' : 'update';
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState<LatLng>(null);
  const [isAddButtonBlocked, setIsAddButtonBlocked] = useState<boolean>(false);
  const { data: sites } = useGetSitesQuery();
  const [
    createSite,
    {
      data: createdSite,
      error: errorCreate,
      isLoading,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateSiteMutation();
  const [
    updateSite,
    { data: updatedSite, error: errorUpdate, isLoading: isUpdateLoading },
  ] = useUpdateSiteMutation();
  const { showError, showSuccess } = useSnackbar();

  function handleMapCenterChange(latLng: LatLng) {
    setLocation(latLng);
  }

  async function geoSearchCountryCode(latLng: LatLng) {
    const provider = new ReverseOpenStreetMapProvider();
    try {
      return await provider.reverseSearchCountryCode(latLng);
    } catch (e) {
      return UNSPECIFIED_COUNTRY_CODE;
    }
  }

  function isValid() {
    return (
      name.length > 0 &&
      name.length < NAME_MAX_LENGTH &&
      description?.length < DESCRIPTION_MAX_LENGTH &&
      location != null
    );
  }

  async function handleCreate() {
    if (isLoading || isUpdateLoading || !isValid()) {
      return;
    }
    setIsAddButtonBlocked(true);
    const countryCode = await geoSearchCountryCode(location);
    const requestBody = {
      name,
      description,
      latitude: location.lat,
      longitude: location.lng,
      countryCode,
    };
    if (actionTitle === 'Add') {
      createSite(requestBody);
      if (setIsShouldValidateSites) setIsShouldValidateSites(true);
    } else {
      updateSite({ data: requestBody, id: siteId });
    }
  }

  useEffect(() => {
    if (!isCreateSuccess || !updateSitesList) return;
    updateSitesList(createdSite);
  }, [isCreateSuccess]);
  useEffect(() => {
    if (siteId && actionTitle === 'Edit' && sites) {
      const s = sites.filter(({ id }) => id === siteId)[0];
      setName(s.name);
      setDescription(s.description);
      setLocation(new LatLng(s.latitude, s.longitude));
    }
  }, [actionTitle, sites, siteId]);
  useEffect(() => {
    if (createdSite || updatedSite) {
      if (typeof onCreate === 'function' && actionTitle === 'Add') {
        onCreate(createdSite.id);
      }
      showSuccess(notifications.success(action));
      onClose();
      setIsAddButtonBlocked(false);
    }
  }, [createdSite, updatedSite, action]);

  useEffect(() => {
    if (!errorCreate && !errorUpdate) return;
    const error = actionTitle === 'Add' ? errorCreate : errorUpdate;
    setIsAddButtonBlocked(false);
    if (isForbiddenError(error)) {
      showError(notifications.permissionsError(action));
      return;
    }
    if (isUniqueViolationError(error)) {
      showError('Site with the same name already exists');
      return;
    }
    showError(notifications.defaultError(action));
  }, [errorCreate, errorUpdate, action]);

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={onClose}
      title={`${actionTitle} a site`}
      actionTitle={actionTitle}
      handleAction={handleCreate}
      isActionButtonDisabled={
        isLoading || isUpdateLoading || !isValid() || isAddButtonBlocked
      }
      actionId={`${actionTitle}Site-action`}
      cancelId={`${actionTitle}Site-cancel`}
    >
      <ModalContent
        sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}
      >
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="name">
            <span>Site name</span>
            <span>
              {name.length}/{NAME_MAX_LENGTH}
            </span>
          </InputLabel>
          <Input
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter site name"
            id="name"
            inputProps={{
              maxLength: NAME_MAX_LENGTH,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description">
            <span>Description</span>
            <span>
              {description?.length ?? 0}/{DESCRIPTION_MAX_LENGTH}
            </span>
          </InputLabel>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
            id="description"
            multiline
            maxRows={4}
            rows={2}
            inputProps={{
              maxLength: DESCRIPTION_MAX_LENGTH,
            }}
          />
        </FormControl>
        <Suspense
          fallback={
            <Loader position="relative" x={50} y={50} nonetransform="true" />
          }
        >
          <PcMap maxZoom={16}>
            <AddLocationRounded
              color="primary"
              fontSize="large"
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -100%)',
                zIndex: '1000',
              }}
            />
            {sites?.map((site) => (
              <Marker
                key={site.id}
                icon={isDark ? onlineDarkIcon : onlineIcon}
                position={{ lat: site.latitude, lng: site.longitude }}
              >
                <Tooltip direction="bottom">{site.name}</Tooltip>
              </Marker>
            ))}
            <CurrentLocationHandler />
            <MapCenterTracker onChange={handleMapCenterChange} />
          </PcMap>
        </Suspense>
      </ModalContent>
    </DialogComponent>
  );
}

CreateSite.defaultProps = {
  onCreate: undefined,
  setIsShouldValidateSites: undefined,
  actionTitle: 'Add',
  siteId: null,
  updateSitesList: undefined,
};

export default CreateSite;
